<template>
	<el-scrollbar class="teacher-page">
		<div class="t-page">
			<div class="t-page-top">
				<div class="item-wrap">
					<div class="item">{{$t('teacher.score.ranking')+':'}} {{memberInfo.total_score_ranking}}</div>
					<div class="item">
						<router-link class="item-link" :to="{name:'teacherScoreSystem',params: {id: $route.params.id}}">
							{{$t('teacher.score.systemRank')+':'}} {{memberInfo.intelligent_score_ranking}}</router-link>
					</div>
					<div class="item"><span class="item-active">{{$t('teacher.score.teacherRank')+':'}} {{memberInfo.teacher_rating_ranking}}</span>
					</div>
				</div>
				<div class="item-wrap">
					<div class="item">{{$t('teacher.score.studentName')+':'}} {{memberInfo.real_name}}</div>
					<div class="item">{{$t('teacher.score.account')+':'}} {{memberInfo.user_name}}</div>
					<div class="item">{{$t('teacher.score.shopName')+':'}} {{memberInfo.store_name}}</div>
				</div>
				<div class="score-table">
					<table width="100%">
						<thead>
							<tr>
								<th width="16.7%">{{$t('teacher.tableHead.total')}}</th>
								<th width="20%">{{$t('teacher.tableHead.totalPoints')}}</th>
								<th width="20%">{{$t('teacher.tableHead.teacherScore')}}</th>
								<th width="20%">{{$t('teacher.tableHead.comment')}}</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td></td>
								<td align="center">21</td>
								<td align="center">{{scoreTotal}}</td>
								<td></td>
								<td></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="t-page-main">
				<el-row v-for="(item,index) in scoreItems" :key="index">
					<el-col :span="4">
						<template v-if="item.sign=='K-001'">
							<el-popover
								placement="right"
								width="200"
								trigger="click">
								<span>{{memberInfo.store_name}}</span>
								<span slot="reference" class="item-link">{{item.name}}</span>
							</el-popover>
						</template>
						<template v-else-if="item.sign=='K-002'">
							<el-popover
								placement="right"
								width="200"
								trigger="click">
								<img :src="memberInfo.store_logo" v-if="memberInfo.store_logo" alt="">
								<span v-else>{{$t('teacher.tags.not')}}{{item.name}}</span>
								<span slot="reference" class="item-link">{{item.name}}</span>
							</el-popover>
						</template>
						<template v-else-if="item.sign=='K-003'">
							<span class="item-link" @click="handlePreview('mStoreInfo',memberInfo.store_id)">{{item.name}}</span>
						</template>
						<template v-else-if="item.sign=='K-004'">
							<a :href="'/teacher/goods-manage?id='+memberInfo.store_id" class="item-link" target="_blank">{{item.name}}</a>
						</template>
						<template v-else-if="item.sign=='K-005'">
							<span class="item-link" v-if="memberInfo.yg_live_id>0" @click="handlePreview('mLiveEdit',memberInfo.yg_live_id)">{{item.name}}</span>
							<el-popover
								placement="right"
								width="200"
								trigger="click"
								v-else>
								<span>{{$t('teacher.tags.not')}}{{item.name}}</span>
								<span slot="reference" class="item-link">{{item.name}}</span>
							</el-popover>
						</template>
						<template v-else-if="item.sign=='K-006'">
							<a :href="'/teacher/live/field?id='+memberInfo.store_id" class="item-link" target="_blank">{{item.name}}</a>
						</template>
						<template v-else-if="item.sign=='K-007'">
							<span class="item-link" v-if="memberInfo.zb_live_id>0" @click="handlePreviewLive(memberInfo.zb_live_id)">{{item.name}}</span>
							<span class="item-link" v-else-if="memberInfo.hf_live_id>0" @click="handlePreview('mLivePlayback',memberInfo.hf_live_id)">{{item.name}}</span>
							<el-popover
								placement="right"
								width="200"
								trigger="click"
								v-else>
								<span>{{$t('teacher.tags.not')}}{{item.name}}</span>
								<span slot="reference" class="item-link">{{item.name}}</span>
							</el-popover>
						</template>
						<template v-else>
							<a href="javascript:void(0);" class="item-link">{{item.name}}</a>
						</template>
					</el-col>
					<el-col :span="5">{{item.remark}}</el-col>
					<el-col :span="5">
						<!-- <el-rate v-model="item.score" :allow-half="true"></el-rate> -->
						<div class="t-page-star">
							<div class="star-wrap">
								<div class="item-star" v-for="star in 5" :key="star"
									@mousemove="starMousemove($event,star,index)" @mouseleave="starMouseleave" @click="starMousedown">
									<template v-if="curScore>0 && curScoreIndex==index">
										<i class="el-icon-star-on" v-if="curScore>star*0.6 || curScore/0.6==star"></i>
										<i class="el-icon-star-off" v-else-if="curScore<star*0.6 && curScore>(star-1)*0.6">
											<i class="el-star__decimal el-icon-star-on" :style="{width:Math.round((curScore%0.6)/0.6*100)+'%'}"></i>
										</i>
										<i class="el-icon-star-off" v-else></i>
									</template>
									<template v-else>
										<i class="el-icon-star-on" v-if="item.score>star*0.6 || item.score/0.6==star"></i>
										<i class="el-icon-star-off" v-else-if="item.score<star*0.6 && item.score>(star-1)*0.6">
											<i class="el-star__decimal el-icon-star-on" :style="{width:Math.round((item.score%0.6)/0.6*100)+'%'}"></i>
										</i>
										<i class="el-icon-star-off" v-else></i>
									</template>
								</div>
							</div>
							<div class="star-tip">{{$t('teacher.score.tips4')+':'}}{{curScore > 0 && curScoreIndex == index ? curScore : item.score}}</div>
						</div>
					</el-col>
					<el-col :span="5">
						<el-input v-model="item.comment" @change="onScoreSave" maxlength="250" size="small" :placeholder="$t('teacher.placeholder.guidance')"></el-input>
					</el-col>
					<el-col :span="5"></el-col>
				</el-row>
			</div>
			
			<el-dialog :width="previeWidth" top="10vh" :visible.sync="dialogIframeVisible">
				<iframe class="dialog-iframe" :src="iframePath"></iframe>
			</el-dialog>
		</div>
	</el-scrollbar>
</template>

<script>
	import { fetchStudentScore, saveTeacherScore } from '@/api/teacher';
	export default {
		data() {
			return {
				memberInfo: {},
				scoreItems: {},
				scoreTotal: 0,
				value1: '',
				value2: null,
				value3: null,
				disabled: false,
				curScore: 0,
				curScoreIndex: -1,
				dialogIframeVisible: false,
				iframePath: '',
				previeWidth: '420px',
			}
		},
		mounted() {
			this.getScoreInfo();
		},
		methods: {
			getScoreInfo() {
				fetchStudentScore({member_id: this.$route.params.id}).then(response => {
					this.memberInfo = response.data.member_info;
					this.scoreItems = response.data.score_items;
					this.scoreItems.forEach(item => {
						item.score = parseFloat(item.score);
						item.disabled = item.comment?true:false;
					})
				})
			},
			// 鼠标移入
			starMousemove(e, index, pindex) {
				this.curScore = Math.round(((index*0.6-0.6)+(e.layerX+1)/20*0.6)*100)/100;
				this.curScoreIndex = pindex;
			},
			// 鼠标离开
			starMouseleave(){
				this.curScore = 0;
				this.curScoreIndex = -1
			},
			// 鼠标按下
			starMousedown(){
				this.scoreItems[this.curScoreIndex].score = this.curScore;
				this.onScoreSave();
			},
			onScoreSave(){
				let params = {
					member_id: this.$route.params.id,
					score_items: this.scoreItems
				}
				saveTeacherScore(params).then(() => {
					this.scoreItems.forEach(item => {
						item.score = parseFloat(item.score);
						item.disabled = item.comment?true:false;
					})
				})
			},
			handlePreview(type,id){
				this.previeWidth = '420px';
				switch(type){
					case 'mStoreInfo':
					this.iframePath = this.$itemBasePath+'/m/store/info/'+id;
					break;
					case 'mLiveEdit':
					this.iframePath = this.$itemBasePath+'/m/uulive/edit/'+id;
					break;
					case 'mLivePlayback':
					this.iframePath = this.$itemBasePath+'/m/uulive/playback/'+id;
					break;
				}
				this.dialogIframeVisible = true;
			},
			handlePreviewLive(id){
				this.previeWidth = '1040px';
				this.iframePath = this.$itemBasePath+'/m/uulive/info/'+id;
				this.dialogIframeVisible = true;
			}
		},
		watch: {
			scoreItems: {
				handler: function(val, oldVal) {
					let total = 0;
					val.forEach(item => {
						total += parseFloat(item.score);
					})
					this.scoreTotal = Math.round(total*100)/100;
				},
				deep: true
			}
		}
	}
</script>

<style lang="scss" scoped>
	.t-page {
		padding: 20px;
		.score-table {

			th,
			td {
				padding: 10px;
				line-height: 20px;
			}
		}

		.t-page-top {
			margin-bottom: 20px;

			.item-wrap {
				margin-bottom: 10px;

				.item {
					width: 200px;
					display: inline-block;
					line-height: 20px;
				}

				.item-link {
					color: #f56c6c;
				}

				.item-link:hover {
					text-decoration: underline;
				}

				.item-active {
					color: $--seller-primary;
				}
			}

			.score-table {
				th {
					background-color: $--seller-thead-background-1;
				}
			}
		}

		.t-page-main {
			.el-row {
				line-height: 32px;
				margin-bottom: 20px;
			}
			.t-page-star {
				width: 120px;
				margin: 0 auto;
				line-height: normal;
				.star-tip {
					color: #f56c6c;
					margin-top: 6px;
				}
			}
			.star-wrap {
				.item-star {
					width: 20px;
					height: 20px;
					position: relative;
					display: inline-block;
					vertical-align: top;
					line-height: normal;
					cursor: pointer;
				}

				.el-icon-star-off {
					color: #c6d1de;
					font-size: 20px;
				}

				.el-icon-star-on {
					top: 0;
					left: 0;
					color: #f7ba2a;
					font-size: 20px;
					position: absolute;
				}

				.el-star__decimal {
					overflow: hidden;
				}
			}
			.item-link {
				color: #333333;
				cursor: pointer;
			}
			.item-link:hover {
				text-decoration: underline;
			}
		}
	}
	.dialog-iframe {
		width: 100%;
		height: 667px;
		border: none;
		box-shadow: 0 0 5px rgba(0,0,0,.5);
	}
</style>
